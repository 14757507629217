
*{
 
  text-rendering: optimizeLegibility !important;
  /* will-change: transform !important; */
  font-family: 'Comic Neue', cursive !important;
}



    .boton-agregar {
      background: #A91EE4 !important;
      color: #FFFFFF;
      border: 0px !important;
    }
    
    .boton-agregar:active {
      background: #A91EE4 !important;
      color: #FFFFFF;
      border: 0px !important;
      box-shadow: none !important;
    }
    .boton-agregar:active:focus {
      background: #A91EE4 !important;
      color: #FFFFFF;
      border: 0px !important;
      box-shadow: none !important;
    }
    .boton-agregar:hover {
      background: #A91EE4 !important;
      color: #FFFFFF;
      border: 0px !important;
      box-shadow: none !important;
    }
    
    .boton-agregar::after {
      background: #A91EE4 !important;
      color: #FFFFFF;
      border: 0px !important;
      box-shadow: none !important;
    }
path {
    transform: rotate(90deg);
    transform-origin:50% 50%;
    /* left:50% */
  }

  /* #contendor{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
  } */

  #contenedor {
    width: 100%;
    height: 50%;
    resize: both;
    overflow: auto;
    /* border: 1px dashed #aaa; */
    margin:0 auto;
    text-align: center;
   }
.stop-watch{
  height: 41px;
  width: 76px;
  background-color: rgba(255,255,255,0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position:absolute;
  top:40px;
  left:80px;
  z-index: 60;
  border-radius: 20px;
}
.Control-Buttons {
    /* margin: 3rem 0; */
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
    
  .btn-grp-cronometro {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
    
  .btn-cronometro {
    /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
    width: 10vw;
    height: 5vh;
    border-radius: 14px;
    /* margin: 0px 6px; */
    display: flex;
    border: 2px solid #e42a2a;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #f5f5f5;
  }
    
  .btn-one-cronometro{
      background-color: #e42a2a;
  }

  
.timer{
  /* margin : 3rem 0; */
  padding:20px;
  width: 100%;
  display: flex;
  height: 12%;
  justify-content: center;
  align-items: center;
}

.digits{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1rem;
  color:  #000000;
}

.mili-sec{
  color:  #e42a2a;
}